<template>
  <div class="bank-id-success">
    <Container>
      <Card padding="large">
        <b-row v-if="isLoading" align-h="center">
          <b-col cols="auto">
            <Loader class="margins__double" :text="$t('LOADING')" />
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12" sm="auto" class="text-center">
            <SuccessSVG class="icon h1 text-white" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal">{{ $t('ORDER_COMPLETE') }}</h1>
              <i18n path="ORDER_COMPLETE_INFO" tag="p">
                <template #reference>
                  <span class="text-strong">{{ loanReferenceNumber }}</span>
                </template>
                <template #paid>
                  <span class="text-strong">{{ paid }}</span>
                </template>
              </i18n>

              <i18n path="ORDER_COMPLETE_CONTRACT" tag="p">
                <template #contractLink>
                  <a :download="`${paid}.pdf`" :href="base64Agreement">
                    {{ $t('ORDER_COMPLETE_CONTRACT_LINK') }}
                  </a>
                </template>
              </i18n>

              <template>
                <div class="list-counter margins__double">
                  <h5>{{ $t('ORDER_COMPLETE_CHECKLIST.STEPS') }}</h5>
                  <ul v-for="(item, index) in LIST_ITEMS" :key="index">
                    <li class="h6 text-normal margins__double">
                      {{ $t(item.title) }}
                    </li>
                    <p>{{ $t(item.text) }}</p>

                    <i18n
                      v-if="item.hasPaidInfo"
                      class="extra--info"
                      path="ORDER_COMPLETE_CHECKLIST.ITEM_4_HINT"
                      tag="p"
                    >
                      <template #link>
                        <a
                          href="https://www.targobank.de/status"
                          target="_blank"
                        >
                          <span class="text-strong">
                            {{ 'https://www.targobank.de/status' }}
                          </span>
                        </a>
                      </template>
                      <template #paid>
                        <span>{{ paid }}</span>
                      </template>
                    </i18n>

                    <p v-else class="extra--info">{{ $t(item.extraInfo) }}</p>
                  </ul>
                </div>
                <Separator size="large" />
                <div class="text-center">
                  <Button @click="$router.push({ name: ROUTES.HOME.name })">
                    {{ $t('MAKE_NEW_ORDER') }}
                  </Button>
                </div>
              </template>
            </Margins>
          </b-col>
        </b-row>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Button,
  Card,
  Container,
  Loader,
  Margins,
  Separator,
} from '@/components';
import SuccessSVG from '@/assets/images/success.svg';
import { constants } from '@/mixins';
import { LIST_ITEMS } from '@/constants';

export default {
  name: 'Success',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    Separator,
    Loader,
    SuccessSVG,
  },
  data() {
    return {
      LIST_ITEMS,
    };
  },

  computed: {
    ...mapState({
      paid: (state) => state.resolution.paid,
      isLoading: (state) => state.isLoading,
      loanReferenceNumber: (state) => state.loanReferenceNumber,
      agreementText: (state) => state.resolution.agreementText,
    }),

    base64Agreement() {
      return 'data:image/png;base64,' + this.agreementText;
    },
  },
};
</script>
<style lang="scss">
li {
  padding-top: 10px;
  padding-bottom: 10px;
}

.list-counter {
  counter-reset: number;
}

.list-counter li::before {
  counter-increment: number;
  content: counter(number) '. ';
  font-family: $font-family-header;
  font-size: 1.25em;
  color: $color-primary;
}
.extra--info {
  opacity: 0.5;
  padding-top: 5px;
}
</style>
